var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "btn-container" },
    [
      [1, 2, 3].includes(_vm.jobData.status) &&
      _vm.hasPermission(_vm.$permissionFunctionCode.EDIT) &&
      (_vm.hasSysAdminPermission ||
        _vm.jobData.mitigatingCompanyOrgId === _vm.currentCompany.id)
        ? _c(
            "el-button",
            {
              attrs: { type: "danger", size: "small", round: "" },
              on: { click: _vm.cancelJop },
            },
            [_vm._v(" Cancel Job ")]
          )
        : _vm._e(),
      ![3, 4, 5].includes(_vm.jobData.status) &&
      _vm.hasPermission(_vm.$permissionFunctionCode.ADD)
        ? _c(
            "el-button",
            {
              staticStyle: { "min-width": "100px" },
              attrs: {
                type: "primary",
                size: "small",
                round: "",
                loading: _vm.loading,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("submit")
                },
              },
            },
            [_vm._v(" Submit ")]
          )
        : _vm._e(),
      _c(
        "el-button",
        {
          staticStyle: { "min-width": "100px" },
          attrs: { outline: "", size: "small", round: "" },
          on: {
            click: function ($event) {
              return _vm.$emit("close", (_vm.noAction = true))
            },
          },
        },
        [_vm._v(" Close ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }